import {
  createBill,
  billHistory,
  inventory,
  account,
  store,
  cafe,
} from "../../../assets/icons";
import { HOT_KEYS } from "../../../helpers/common";

export const navItems = [
  {
    name: "New Bill",
    navLink: "/create-bill",
    icon: createBill,
    hotKey: HOT_KEYS.F1,
  },
  {
    name: "Invoice",
    navLink: "/bill-history",
    icon: billHistory,
    hotKey: HOT_KEYS.F2,
  },
  {
    name: "Inventory",
    navLink: "/inventory",
    icon: inventory,
    hotKey: HOT_KEYS.F3,
  },
  { name: "Account", navLink: "/account", icon: account, hotKey: HOT_KEYS.F4 },
  {
    name: "Barcode ",
    navLink: "/barcode",
    icon: createBill,
    hotKey: HOT_KEYS.F5,
  },
];
export const cafeItems = [
  {
    name: "Create New Bill",
    navLink: "/create-bill",
    icon: createBill,
    hotKey: HOT_KEYS.F1,
  },
  {
    name: "Bill History",
    navLink: "/bill-history",
    icon: billHistory,
    hotKey: HOT_KEYS.F2,
  },
  {
    name: "Inventory",
    navLink: "/inventory",
    icon: inventory,
    hotKey: HOT_KEYS.F3,
  },
  { name: "Account", navLink: "/account", icon: account, hotKey: HOT_KEYS.F4 },
];
export const CafeNavItems = [
  {
    id: 1,
    name: "Store",
    value: "storeadmin",
    icon: store,
    hotKey: HOT_KEYS.F1,
  },
  { id: 2, name: "Cafe", value: "cafeAdmin", icon: cafe, hotKey: HOT_KEYS.F2 },
];
